<template>
  <div id="heatmapoptions">
    <div>
      <h4>Cell radius</h4>
      <div class="block">
        <el-slider
          @change="updateChart"
          v-model="plotOptions.heatmap.radius"
          :max="100"
          :min="0"
          show-input
        ></el-slider>
      </div>
    </div>

    <div>
      <h4>Color Shade Intensity</h4>
      <div class="block">
        <el-slider
          @change="updateChart"
          v-model="plotOptions.heatmap.shadeIntensity"
          :max="0.8"
          :min="0"
          :step="0.05"
          show-input
        ></el-slider>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      plotOptions: {
        heatmap: {
          radius: 2,
          shadeIntensity: 0.5,
        },
      },
    };
  },
  watch: {
    chartConfig: function(newProp) {
      const p = newProp.plotOptions;
      this.setNewOptions(p);
    },
  },
  mounted: function() {
    const p = this.getChartConfig().w.config.plotOptions;
    this.setNewOptions(p);
  },
  methods: {
    setNewOptions(p) {
      this.plotOptions = {
        heatmap: {
          radius: p.heatmap.radius,
          shadeIntensity: p.heatmap.shadeIntensity,
        },
      };
    },
    updateChart: function() {
      const p = this.plotOptions;
      this.updateChartOptions({
        plotOptions: {
          heatmap: {
            radius: p.heatmap.radius,
            shadeIntensity: p.heatmap.shadeIntensity,
          },
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
